<template>
  <div>
    <b-card no-body>
      <messages-filter
        :platform-filter.sync="platformFilter"
        :trashed-filter.sync="trashedFilter"
        :platform-options="platformOptions"
        :trashed-options="trashedOptions"
      />
      <messages-list
        :api-url="apiUrl"
        :platform-filter="platformFilter"
        :trashed-filter="trashedFilter"
        :table-columns="tableColumns"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import axiosIns from '@/libs/axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import MessagesList from '@/components/lists/messages/MessagesList.vue'
import MessagesFilter from '@/components/lists/messages/MessagesFilter.vue'

export default {
  name: 'Messages',
  components: {
    BCard,
    MessagesFilter,
    MessagesList,
  },
  data() {
    return {
      platformFilter: null,
      platformOptions: [],
      trashedFilter: false,
      trashedOptions: [
        { label: 'Oui', value: true },
        { label: 'Non', value: false },
      ],
      tableColumns: [
        {
          key: 'user',
          label: 'Utilisateur',
          sortable: false,
        },
        {
          key: 'content',
          label: 'Message',
          sortable: true,
        },
        {
          key: 'created_at',
          label: 'Date de création',
          sortable: true,
        },
        {
          key: 'actions',
        },
      ],
      apiUrl: 'messages',
    }
  },
  mounted() {
    this.fetchPlatforms()
  },
  methods: {
    async fetchPlatforms() {
      try {
        const { data } = await axiosIns.get('/platforms/minimal')
        data.forEach(platform => {
          this.platformOptions.push({ label: platform.name, value: platform.uuid })
        })
      } catch (err) {
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erreur',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: `Une erreur s'est produite. erreur: ${err}`,
            },
          },
          { timeout: 5000 },
        )
      }
    },
  },
}
</script>

<style lang="scss">
.per-page-selector {
  width: 90px;
}
@import "@core/scss/vue/libs/vue-select.scss";
</style>
