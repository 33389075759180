<template>
  <div>
    <b-card-header class="pb-50">
      <h5>Filtres</h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          v-if="platformOptions"
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Plateforme</label>
          <v-select
            :dir="'ltr'"
            :value="platformFilter"
            :options="platformOptions"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:platformFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Supprimées</label>
          <v-select
            :dir="'ltr'"
            :value="trashedFilter"
            :options="trashedOptions"
            :clearable="false"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:trashedFilter', val)"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </div>
</template>

<script>
import {
  BCardBody, BCardHeader, BCol, BRow,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'MessagesFilter',
  components: {
    BRow,
    BCol,
    BCardHeader,
    BCardBody,
    vSelect,
  },
  props: {
    platformFilter: {
      type: [String, null],
      default: null,
    },
    trashedFilter: {
      type: [Boolean, null],
      default: null,
    },
    platformOptions: {
      type: Array,
      default: null,
    },
    trashedOptions: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style scoped lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
